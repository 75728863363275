import React from 'react';
import { MediaImage } from '~/components/elements/Media';
import { StaticImage } from 'gatsby-plugin-image';

export const Mission = (props) => {
	const { by, heading, content } = props;
	return (
		<div className="relative">
			<StaticImage src="../../assets/images/mission-illustration-1.png" className="hidden lg:block w-[12%] !absolute top-0 left-0" alt="" />
			<StaticImage src="../../assets/images/mission-illustration-2.png" className="hidden lg:block w-[22%] !absolute right-0 top-1/2 transform -translate-y-1/2" alt="" />
			<div className="container relative mb-10 md:mb-0">
				<div className="max-w-[734px] mx-auto md:space-y-10">
					<div className="space-y-5 md:space-y-10">
						<h3 className="t-45">{heading}</h3>
						<div className="whitespace-pre-wrap text-navy mission-content" dangerouslySetInnerHTML={{__html: content}} />
					</div>
					<div className="flex items-center space-x-8">
						<div className="w-[70px] h-[70px] rounded-full overflow-hidden">
							<MediaImage image={by.image} />
						</div>
						<div className="">
							<MediaImage image={by.signature} className="w-[160px]" />
							<div className="t-14 t-black-65" dangerouslySetInnerHTML={{ __html: by.role }} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
