import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MediaImage } from '~/components/elements/Media';

export const Logos = (props) => {
	const { logos } = props;
	return (
		<div className="container py-10 md:py-0 ">
			<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-y-12">
				{logos.map((logo, i) => (
					<MediaImage key={i} image={logo.image} className="w-[130px] h-[40px] mx-auto" objectFit="contain" />
				))}
			</div>
		</div>
	);
};
