import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { Media, MediaImage } from '~/components/elements/Media';
import { LinkInner } from '~/components/elements/Link';
import { Tick } from '~/components/elements/Icon';

export const TwoColumns = (props) => {
	const { centredText, columnA, columnB, flipColumns, showLogos, headingSize, headingWidth, showUsps, showSocials } = props;
	return (
		<div className="container">
			<div className={`flex flex-col-reverse lg:flex-row items-center lg:justify-between ${flipColumns && 'lg:flex-row-reverse'}`}>
				<ColumnA {...columnA} flipColumns={flipColumns} centredText={centredText} showLogos={showLogos} headingSize={headingSize} headingWidth={headingWidth} showUsps={showUsps} showSocials={showSocials} />
				<ColumnB {...columnB} />
			</div>
		</div>
	);
};


export const ColumnA = ({heading, content, features, usps, links, logos, socials, flipColumns, centredText, showLogos, showUsps, headingSize = "50", headingWidth = "auto", showSocials}) => {

	const headingClasses = ['text-navy ']

	if(headingSize === '59' ){
		headingClasses.push('t-59')
	} else if(headingSize === '45' ){
		headingClasses.push('t-45')
	} else {
		headingClasses.push('t-50')
	}

	if(headingWidth === '442'){
		headingClasses.push('max-w-[442px] mx-auto')
	} else if (headingWidth === '537') {
		headingClasses.push('max-w-[537px] mx-auto')
	}

	return (
		<div className={`w-full  mt-8 lg:mt-0 ${headingWidth === '537' ? 'lg:w-[45%]' : 'lg:w-2/5'} `}>
			<div className="max-w-[442px] max-w-[537px] max-w-[480px]" />
			<div className={`space-y-4 lg:space-y-8 ${headingWidth === '537' ? 'max-w-[537px]' : 'max-w-[480px]'}  ${centredText && 'text-center mx-auto'} ${flipColumns ? 'lg:ml-0' : 'lg:ml-auto'}`}>
				{heading && <h3 className={headingClasses.join(' ')}>{heading}</h3>}
				{content && <p className={`t-18 t-black-65 max-w-[460px]  whitespace-pre-wrap ${centredText && 'mx-auto'}`}>{content}</p>}
				{features && (
					<ul className="space-y-3">
						{features.map((feature, i) => (
							<li key={i} className="flex items-center space-x-3">
								<div className="w-4 h-4 flex items-center justify-center bg-green rounded-full text-white">
									<div className="w-2 fw-svg">
										<Tick />
									</div>
								</div>
								<div className="font-semibold text-black t-19">
									{feature.text}
								</div>
							</li>
						))}
					</ul>
				)}
				{showLogos && (
					<div className="flex py-4 md:py-0 space-x-2 flex-wrap items-center -ml-6">
						{logos?.map((logo, i) => (
							<MediaImage key={i} image={logo} className="block w-auto flex-1 h-full max-w-[116px] max-h-[27px]" />
						))}
					</div>
				)}
				{showUsps && (
					<div className="flex flex-col space-y-5 pt-8">
						{usps?.map((usp, i) => (
							<div key={i} className="flex items-center space-x-8">
								<h5 className="t-25">{usp.text}</h5>
								<div className="bg-blue-light text-[7px] uppercase px-2.5 py-1.5 rounded-[25px] border border-[#CDDBF8]  tracking-[.01em] text-[#5C7BB8]">Coming soon</div>
							</div>
						))}
					</div>
				)}
				{links && (
					<div className={`flex flex-col space-y-5 md:space-y-0 md:flex-row flex-wrap md:items-center md:space-x-12 ${centredText && 'justify-center'}`}>
						{links?.map((link, i) => (
							<div key={i}>
								<LinkInner {...link} />
							</div>
						))}
					</div>
				)}
				{showSocials && (
					<div className="flex items-center justify-between border border-navy border-opacity-20  py-4 p-5 rounded-[15px] max-w-[374px]">
						<div className="text-navy font-semibold">{socials.label}</div>
						<div className="flex items-center space-x-2.5">
							{socials?.links?.map((social, i) => (
								<a key={i} href={social.link.url} target={social.link.target} className="border border-navy border-opacity-20 transition-colors duration-200 ease-in-out hover:bg-white rounded-[10px] p-2.5">
									<div className="w-4">
										<MediaImage image={social.icon} />
									</div>
								</a>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}


export const ColumnB = ({image, type, video}) => {
	return (
		<div className="w-full lg:w-1/2">
			<Media image={image} type={type} vide={video} />
		</div>
	)
}

