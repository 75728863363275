import React, { useRef, useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const InViewAnimator = ({ inner, className }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
    if (!inView) {
      // controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <div className={className} ref={ref}>
      {inner(controls)}
    </div>
  );
};

export const InViewAnimatorBasicItem = ({
  className,
  controls,
  delay,
  children,
  duration = 1.75,
  prop = 'y',
  start = 50,
}) => {
  const startProps = { opacity: 0 };
  startProps[prop] = start;
  const endProps = {
    opacity: 1,
    transition: {
      duration: duration,
      delay: 0.2 + delay,
      ease: [0.04, 0.5, 0.2, 1],
    },
  };
  endProps[prop] = 0;
  return (
    <motion.div
      className={className}
      initial="hidden"
      variants={{
        hidden: startProps,
        visible: endProps,
      }}
      animate={controls}
    >
      {children}
    </motion.div>
  );
};

export const HeightFixer = ({ children, className, extraHeight = 30, extraHeightMobile }) => {
  const innerRef = useRef();
  const [height, set_height] = useState(0);
  const [width, set_width] = useState(0);
  if (!extraHeightMobile) extraHeightMobile = extraHeight;
  useEffect(() => {
    if (typeof window === 'undefined') return null;
    set_width(window.outerWidth);
    const setHeight = () => {
      if (!innerRef.current) return;
      set_height(innerRef.current.offsetHeight);
    };
    window.setTimeout(() => {}, 100);
    setHeight();
    let timeout = window.setTimeout(() => {
      setHeight();
    }, 1000);
    window.addEventListener('resize', setHeight);
    return () => {
      window.clearTimeout(timeout);
      window.removeEventListener('resize', setHeight);
    };
  }, [innerRef]);
  return (
    <div
      style={{ height: height > 0 ? `${height + (width < 768 ? extraHeightMobile : extraHeight)}px` : 'auto' }}
      className={`box-content ${className}`}
    >
      <div ref={innerRef}>{children}</div>
    </div>
  );
};
