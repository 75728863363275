import React from 'react';
import Marquee from 'react-fast-marquee';
import { Media, MediaImage } from '~/components/elements/Media';

export const TwoColumnLogos = (props) => {
	const { columnA, columnB} = props;
	return (
			<div className={`container-full flex flex-col space-y-10 lg:flex-row items-center`}>
				<div className="container-inner w-full lg:w-[52%]">
					<ColumnA {...columnA} />
				</div>
				<div className="w-full lg:w-[48%]">
					<ColumnB {...columnB}  />
				</div>
			</div>
	);
};

export const ColumnA = ({heading, content}) => {

	return (
		<div className={'max-w-[529px] mx-auto lg:mx-0'}>
			<div className={`space-y-4 lg:space-y-8 text-center lg:text-left`}>
				{heading && <h3 className="t-50 text-navy">{heading}</h3>}
				{content && <p className="t-18">{content}</p>}
			</div>
		</div>
	)
}

export const ColumnB = ({logos}) => {
	return (
		<div className="marquee-gradient ">
			<div gradient={false} speed={60} className="flex items-center justify-center">
				{logos.map((logo, i) => (
					<Logo logo={logo} margin={true} key={i} />
				))}
    	</div>
		</div>
	)
}

export const Logo = ({ logo, margin }) => (
  <div className={` w-auto flex items-center ${margin ? 'mx-5 md:mx-10' : ''}`}>
    <MediaImage image={logo} className="w-[152px] md:w-[202px] " objectFit="contain" objectPosition="centre" />
  </div>
);