import React from 'react';
import { MediaImage } from '~/components/elements/Media';
import { LinkInner } from '~/components/elements/Link';
import { Tick } from '~/components/elements/Icon';

export const InsurancePlans = (props) => {
	const { plans } = props;

	return (
		<div className="container">
			<div className="lg:grid grid-cols-4 space-y-10 lg:space-y-0">
				{/* <div className="hidden lg:block col-start-2 col-span-3">
					<div className="font-semibold text-navy mb-8 t-22-normal">
						Examples of plans include:
					</div>
				</div>
				<div className="col-span-1">
					<div className="relative h-full">
						<div>
							<div className="t-20 font-semibold">Filter</div>
						</div>
						Filter goes here
					</div>
				</div> */}
				<div className="col-span-4">
					<div className="font-semibold text-navy mb-8 t-22-normal">
						Examples of plans include:
					</div>
				</div>
				<div className="col-span-4 grid sm:grid-cols-2 lg:grid-cols-3 gap-10">
					{plans.map((plan, i) => (
						<div className="bg-white text-navy card-shadow p-5 py-8 lg:p-8 space-y-6 flex flex-col rounded-[20px]">
							<div className="border-b border-b-black border-opacity-10 pb-6 space-y-8">
								{plan.image && <MediaImage image={plan.image} className="w-[143px] h-[30px]" />}
								<div className="space-y-4">
									<h5 className="t-32 !text-navy">{plan?.heading}</h5>
									<p>{plan?.content}</p>
								</div>
							</div>
							<div className=" pb-6">
								<div className="space-y-5">
									<div className="t-18 font-semibold text-navy">{plan.benefits.heading}</div>
									<ul className="space-y-3">
										{plan.benefits.benefits.map((benefit, i) => (
											<li className="flex items-center space-x-3">
												<div className="text-green">
													<Tick />
												</div>
												<div className="font-medium t-black-65">
													{benefit.content}
												</div>
											</li>
										))}
									</ul>
								</div>
							</div>
							<div className="!mt-auto button-fw">
								{plan.link && <LinkInner {...plan.link} />}
							</div>
						</div>
					))}



				</div>
			</div>
		</div>
	);
};
