import { graphql, useStaticQuery } from "gatsby";

const useProductAttributes = () => {
  const data = useStaticQuery(graphql`
    query {
      allWcProductsAttributes(sort: { fields: position, order: DESC }) {
        nodes {
          id
          type
          name
          position
          attribute_options {
            name
            slug
          }
          slug
        }
      }
    }
  `);

  return data;
};

export default useProductAttributes;
