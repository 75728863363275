import { useField } from 'formik';
import React from 'react';

export default function CheckboxField(props) {
  const { label, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  return (
    <label className={`flex !text-black items-start flex-auto relative space-y-1 group  ${props.className || ''}`}>
      <input
        type="checkbox"
        style={{ flex: '0 0 18px' }}
        className={`cursor-pointer mt-2 rounded-[2px] w-[18px] h-[18px] border border-[#ABABAB] group-hover:border-navy focus-within:border-navy bg-transparent appearance-none outline-none group-hover:border-opacity-100 focus:border-opacity-100 checked:border-opacity-100 transition-colors duration-200 ease-in-out `}
        {...field}
        {...other}
      />
      <div
        className={`t-17 text-left cursor-pointer pl-2.5`}
        dangerouslySetInnerHTML={{ __html: label }}
      />
      {isInvalid && <ErrorMessage className=" top-[95%]" error={meta.error} />}
    </label>
  );
}

export const ErrorMessage = ({ error = '', className = '' }) => {
  return (
    <div className={`text-[10px] text-error flex items-center mt-1 left-0 absolute ${className}  `}>{error}</div>
  );
};
