import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { LinkInner } from '~/components/elements/Link';
import { MediaImage } from '~/components/elements/Media';

export const Cta = (props) => {
	const { subheading, heading, content, ctaLink, imageLeft, imageRight, imagesLayout, layout } = props;

	const imageLeftClasses = ['hidden md:block !absolute']
	const imageRightClasses = ['hidden md:block !absolute']

	if(imagesLayout === '2') {
		imageLeftClasses.push('w-[52%] top-1/2 transform -translate-y-1/2 left-[-40%] lg:left-[-31%]')
		imageRightClasses.push('w-[25%] top-1/2 transform -translate-y-1/2 right-[-10%] lg:right-[-3%]')
	} else {
		imageLeftClasses.push('w-[20%] bottom-0 left-0')
		imageRightClasses.push('w-[19%] bottom-8 right-0')
	}

	return (
		<div className="px-5 md:py-12 lg:py-32 lg:pt-20 container container-full relative">
			<div className="">
				<MediaImage image={imageLeft} objectFit="contain" className={imageLeftClasses.join(' ')} />
				<MediaImage image={imageRight} objectFit="contain" className={imageRightClasses.join(' ')}  />
			</div>
			<div className="container relative">
				<div className="space-y-8 text-center px-5">
					<div className="space-y-5">
						{subheading && <h6 className="font-body font-semibold t-14-upper tracking-[.16em] text-green masthead-subheading">{subheading}</h6>}
						<h2 className={`t-80  mx-auto text-center max-w-[878px]`}>{heading}</h2>
						<p className={`t-18 text-center mx-auto ${layout === '404' ? 'max-w-[485px]' : 'max-w-[637px]'} `}>{content}</p>
					</div>
					{ctaLink && (
						<div className="cta-button">
							<LinkInner {...ctaLink} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
