import React, { useEffect, useState } from 'react';
import { LinkInner } from '~/components/elements/Link';
import getJobs from "~/utils/getJobs";
import { ChevronRight } from '~/components/elements/Icon'

export const Careers = (props) => {
	const { heading, careersLink } = props;

	const [jobList, setJobList] = useState([]);

	useEffect(async () => {
		const jobs = await getJobs();
		setJobList(jobs);
	}, []);


	return (
		<div className="container " id="careers">
			<div className="space-y-20">
				<h2 className="t-59 text-center max-w-[540px] mx-auto">
					{heading}
				</h2>
				<div className="space-y-12 max-w-[840px] mx-auto">
					<div className="space-y-5 text-left">
						{jobList?.map((job, i) => (
							<a href={job.hostedUrl} target="_blank" className="bg-white card-shadow rounded-[20px] flex justify-between items-center p-5 px-7 group">
								<div className="flex flex-col space-y-2 md:flex-row md:items-center w-4/5">
									<h4 className="t-21 md:w-2/3">{job.text}</h4>
									<div className="text-blue font-medium md:w-1/4 md:ml-auto">{job.categories.location}</div>
								</div>
								<div className={`w-8 h-8 rounded-full flex items-center justify-center bg-blue group-hover:bg-navy transition-colors duration-300 ease-in-out`} >
									<div className="w-5 flex items-center justify-center text-white ml-px">
										<ChevronRight />
									</div>
								</div>
							</a>

						))}
					</div>
					<div className="text-center">
						<LinkInner {...careersLink} />
					</div>
				</div>
			</div>
		</div>
	);
};
