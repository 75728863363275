import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MediaImage } from '~/components/elements/Media';

export const Usps = (props) => {
	const { heading, usps } = props;
	return (
		<div className="container">
			<div className="space-y-10">
				<h2 className="t-45 text-center">{heading}</h2>
				<div className="flex flex-col md:flex-row justify-between space-y-10 md:space-y-0">
					{usps.map((usp, i) => (
						<Usp  key={i} {...usp} />
					))}
				</div>
			</div>
		</div>
	);
};


export const Usp = ({image, heading, content}) => {
	return (
		<div className="text-center md:w-[33%] space-y-5">
			<div className="w-28 h-28 mx-auto">
				<MediaImage image={image} objectFit="contain" objectPosition="center" className="w-full h-full" />
			</div>
			<div className="space-y-3">
				<h5 className="t-21">{heading}</h5>
				<p className="max-w-[305px] mx-auto t-black-65">{content}</p>
			</div>
		</div>
	)
}
