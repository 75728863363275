import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MediaImage } from '~/components/elements/Media';
import { Pin } from '~/components/elements/Icon';


export const FeaturedTeam = (props) => {
	const { heading, content, members, headingSize } = props;

	const headingClasses = [' mx-auto']

	if(headingSize === '45'){
		headingClasses.push('t-45 max-w-[523px]')
	} else {
		headingClasses.push('t-65 max-w-[840px]')
	}

	return (
		<div className="container space-y-[54px]">
			<div className="text-center space-y-7">
				{heading && <h2 className={headingClasses.join(' ')}>{heading}</h2>}
				{content && <p className="t-18 max-w-[670px] mx-auto t-black-65">{content}</p>}
			</div>
			<div className="grid sm:max-w-[420px] mx-auto lg:max-w-none space-y-6 lg:space-y-0 lg:mx-0 lg:grid-cols-3 lg:gap-x-6">
				{members.map((member, i) => (
					<TeamMember key={i} {...member} />
				))}
			</div>
		</div>
	);
};

export const TeamMember = ({ image, quote, namerole, role, location }) => {
	return (
		<div className={`space-y-7 group pb-8 lg:pb-0  ${quote && 'cursor-pointer'} `}>
			<div className="relative">
				<div className="rounded-[8px] overflow-hidden">
					<MediaImage image={image} />
				</div>
				{quote && (
					<div className="hidden lg:flex absolute inset-2 top-3 bottom-3 rounded-[8px] bg-white p-9 text-center flex-col justify-center opacity-0 group-hover:opacity-100 duration-300 ease-in-out">
						<h5 className="t-23">
							{quote}
						</h5>
					</div>
				)}
			</div>
			{quote && (
				<div className="lg:hidden text-center flex flex-col justify-center ">
					<h5 className="text-[22px] font-medium tracking-[-.03em] leading-[1.3]">
						{quote}
					</h5>
				</div>
			)}
			<div className="space-y-1 text-center lg:text-left">
				{namerole && <div className="t-21-normal leading-[-.02em] text-navy" dangerouslySetInnerHTML={{ __html: namerole }} />}
				{role && <div className="t-18 font-medium text-blue">{role}</div>}
				{location && <div className="t-18 font-medium text-green space-x-1.5 flex items-center justify-center lg:justify-start"><Pin /><div>{location}</div></div>}
			</div>
			
		</div>
	)
}
