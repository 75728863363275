import React from 'react';
import { ContentRenderer } from '~/components/layouts/ContentRenderer';

export const Section = ({
	prefix,
	backgroundColour,
	previousBackground,
	nextBackground,
	classname,
	hide,
	addLine,
	content = [],
	components = [],
}) => {
	// if (hide) return null
	if (!content.length) content = components;

	const classes = ['section'];

	if (previousBackground !== backgroundColour) {
		classes.push('section-top');
	}
	if (nextBackground !== backgroundColour) {
		classes.push('section-bot');
	}

	const backgroundColourToPush = getBgClass(backgroundColour)

	classes.push(backgroundColourToPush)

	let layouts = 'multiple';
	if (content && content.length === 1) {
		layouts = content[0]?.fieldGroupName.replace(prefix, '');
	}

	if (classname) {
		classes.push(classname);
	}

	if(addLine) {
		classes.push('border-b border-b-black border-opacity-10 section-border')
	}

	return (
		<section data-layouts={layouts} data-background={backgroundColour} className={classes.join(' ')}>
			<ContentRenderer prefix={prefix} content={content} />
		</section>
	);
};

export const getBgClass = (background) => {

	if (background === 'white') {
	} else if (background === 'light-blue') {
		return 'bg-blue-light'
	} else if (background === 'beige') {
		return 'bg-beige'
	} else if (background === 'dark-green') {
		return 'bg-green-dark text-white'
	} else if (background === 'light-green') {
		return 'bg-green-light'
	} else if (background === 'blue') {
		return 'bg-blue text-white'
	} else if (background === 'navy') {
		return 'bg-navy text-white'
	} 
};
