import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MediaImage } from '~/components/elements/Media';
import { LinkInner } from '~/components/elements/Link';

export const Steps = (props) => {
	const { heading, steps } = props;
	return (
		<div className="container space-y-8 md:space-y-20 xl:space-y-28">
			<h2 className="t-65 max-w-[979px] mx-auto text-center">{heading}</h2>
			<div>
				{steps.map((step, i) => (
					<Step  key={i} {...step} i={i} last={(i + 1) === steps.length} />
				))}
			</div>
		</div>
	);
};

export const Step = ({ image, subheading, heading, content, stepLink, i, last }) => {

	const number = (i + 1) < 10 && '0' + (i + 1)

	return (
		<InViewAnimator 
			className={`grid lg:grid-cols-12 items-start py-10 lg:py-[68px] first:lg:pt-0 last:lg:pb-0 `}
			inner={(controls) => (
				<>
					<div className="col-span-2 hidden xl:block sticky top-28">
						<span className="t-65-normal text-blue ">{number}</span>
					</div>
					<div className="col-span-5">
						<InViewAnimatorBasicItem className="overflow-hidden rounded-[8px]"  controls={controls} duration={.5} delay={.2}>
							<MediaImage image={image} objectFit="cover" />
						</InViewAnimatorBasicItem>
					</div>
					<div className="col-span-5 lg:pl-20 lg:pr-[42px]">
						<InViewAnimatorBasicItem className="space-y-6 pt-5 text-center lg:text-left max-w-[588px] mx-auto lg:mx-0 lg:max-w-none" controls={controls} duration={.5} delay={.3}>
							<span className="text-green font-semibold tracking-[-.001em]">Step {number}</span>
							<h4 className="t-32 max-w-[350px] mx-auto lg:mx-0">{heading}</h4>
							<p className="t-18 t-black-65 leading-[1.55] ">{content}</p>
							{stepLink.link && <LinkInner {...stepLink} />}
						</InViewAnimatorBasicItem>
					</div>
				</>
			)}
		/>
	)
}
