import { useField } from 'formik';
import React from 'react';

export default function StateField(props) {
  const { label, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  return (
    <div className={`flex flex-col !text-black items-start flex-auto relative space-y-1  ${props.className || ''}`}>
      <label className={`font-medium t-17`}>{label}</label>
      <select
        placeholder={label}
        className={`${field.value === '' ? '!text-[#A3A3A3]' : '!text-black !text-opacity-[.65]'} cursor-pointer border rounded-[4px] border-navy border-opacity-20 appearance-none select-field !text-black text-opacity-[.65] bg-transparent w-full px-4 py-3 t-17 outline-none focus:border-opacity-100 transition-colors  ${
          isInvalid && 'border border-error !border-opacity-100 '
        }`}
        {...field}
        {...other}
      >
        <option value="" selected>Select state</option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </select>
      {isInvalid && <ErrorMessage className=" top-[98%]" error={meta.error} />}
    </div>
  );
}

export const ErrorMessage = ({ error = '', className = '' }) => {
  return (
    <div className={`text-[10px] text-error flex items-center mt-1 left-0 absolute ${className}  `}>{error}</div>
  );
};
