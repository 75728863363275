import React, { useState, Fragment } from 'react';
import { Link } from 'gatsby';
import Scroll from 'react-scroll';
import {
  SmallPlayIcon,
} from '~/components/elements/Icon';
import ModalProfessionalsForm from '~/components/elements/ModalProfessionalsForm';
import ModalContactForm from '~/components/elements/ModalContactForm';

const ScrollLink = Scroll.Link;

export const MaybeLink = ({ link = {}, triggerModal = false, modal = '', className = 'block', children, style = null, onMouseEnter = null }) => {
  const [open, setOpen] = useState(false);

  if (link && link.link) link = link.link;
  const isLink = link && link.url && !triggerModal ? true : false;
  let Tag;
  let extraProps = {};
  if (isLink) {
    if (link.url && link.url[0] === '#') {
      Tag = ScrollLink;
      extraProps = {
        to: link.url.replace('#', ''),
        smooth: true,
        offset: -80,
        duration: 500,
      };
    } else {
      Tag = 'a';
    }
  } else {
    Tag = 'div';
  }
  return (
    <Tag
      onMouseEnter={onMouseEnter}
      onClick={(triggerModal && modal) ? () => setOpen(true) : null}
      href={link ? link.url : null}
      className={`block${isLink ? ' group' : ''}${triggerModal ? ' cursor-pointer' : ''} ${className}`}
      style={style}
      target={link?.target}
      {...extraProps}
    >
      {children}
      {(triggerModal && modal) && (
        <>
          {modal === 'professionals' ? (
            <ModalProfessionalsForm open={open} setOpen={setOpen} />
          ) : modal === 'contact' && (
            <ModalContactForm open={open} setOpen={setOpen} />
          )}
        </>
      )}
    </Tag>
  );
};

export const UnderlineLink = ({ link, type }) => {
  const linkClasses = ['link-underline'];

  if(!link) return null

  return (
    <div className={linkClasses.join(' ')}>
      <span>{link.title}</span>
      <span aria-hidden={true}>{link.title}</span>
    </div>
  );
};

export const Button = ({ link, buttonStyle, size, icon }) => {

  if (link?.link) link = link.link;

  if(!link) return null;

  const classes = ['button'];
  let iconColor = 'white';
  if (buttonStyle === 'primary') {
    classes.push('button-primary');
  }

  if (buttonStyle === 'secondary') {
    classes.push('button-secondary');
  }

  if (buttonStyle === 'outline') {
    classes.push('button-outline');
  }

  if (buttonStyle === 'outline-small') {
    classes.push('button-outline-small');
  }

  if (size === 'header') {
    classes.push('button-header');
  }

  return (
    <div className={classes.join(' ')}>
      <span>{link?.title}</span>
      <span aria-hidden={true}>{link.title}</span>
    </div>
  );
};

export const LinkInner = ({link, type, triggerModal, modal}) => {
  return (
    <MaybeLink link={link} triggerModal={triggerModal} modal={modal}>
      {type === 'button' ? (
        <Button link={link} buttonStyle="primary" />
      ) : type === 'button-secondary' ? (
        <Button link={link} buttonStyle="secondary" />
      ) : type === 'button-outline' ? (
        <Button link={link} buttonStyle="outline" />
      ) : type === 'button-outline-small' ? (
        <Button link={link} buttonStyle="outline-small" />
      ) : (
        <UnderlineLink link={link}  />
      )}
    </MaybeLink>
  )
}
