import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { AnimatePresence, motion } from "framer-motion";
import { MediaImage } from '~/components/elements/Media';

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay } from "swiper";

import "swiper/css";
import { useLayoutEffect } from 'react';

export const Testimonials = (props) => {
	const { testimonialCategories } = props;
	const [activeCategory, setActiveCategory] = useState(testimonialCategories[0].name)

	return (
		<div className="">
			<div className="container">
				<StaticImage src="../../assets/images/testimonial-bg-blue.png" alt="" className="w-[50%] md:w-[32%] !absolute top-0 right-0 max-w-[460px]" />
				<div className="flex mb-10 md:mb-0 md:justify-end relative">
					<TestimonialCategories categories={testimonialCategories} activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
				</div>
			</div>

			<div className="md:-mt-5 relative">
				<TestimonialsInner categories={testimonialCategories} activeCategory={activeCategory} />
			</div>
		</div>
	);
};

export const TestimonialCategories = ({ categories, activeCategory, setActiveCategory }) => {
	return (
		<div className="md:ml-auto mx-auto md:mr-0 flex justify-center md:justify-end items-center bg-[#8FB2FA] rounded-[46px] p-1 space-x-1 relative z-10">
			{categories.map((category, i) => (
				<button key={i} className={`block cursor-pointer px-2 py-2 w-[100px] md:w-[120px] text-center rounded-[46px] text-black t-14 hover:bg-white transition-colors duration-200 ease-in-out ${activeCategory === category.name && 'bg-white'}`} onClick={() => setActiveCategory(category.name)}>{category.name}</button>
			))}
		</div>
	)
}


export const TestimonialsInner = ({ categories, activeCategory }) => {

	return (
		<>
			<AnimatePresence>
				{categories.map((category, i) => (
					<React.Fragment key={i}>
						{category.name === activeCategory && (
							<TestimonialsInnerSwiper category={category} activeCategory={activeCategory} />
						)}
					</React.Fragment>
				))}
			</AnimatePresence>
		</>
	)
}


export const TestimonialsInnerSwiper = ({category, activeCategory}) => {

	const [active, setActive] = useState(0)
	const [swiper, setSwiper] = useState(null);

	const slideTo = (index) => swiper?.slideTo(active);

	useLayoutEffect(() => {
    slideTo(active ? active : 0);
  }, [active]);

	const overrideActive = (i) => {
    setActive(i);
    slideTo(i)
  };

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.5, ease: "easeInOut" }}
			className={`${category.name === activeCategory ? 'block' : 'hidden'}`}>
			<Swiper
				onSwiper={setSwiper}
				modules={[A11y, Autoplay]}
				className={`w-full z-10 relative`}
				slidesPerView={'auto'}
				autoplay={{
					delay: 8000,
					disableOnInteraction: false
				}}
				onSlideChange={(swiperCore) => {
					const {
						activeIndex,
					} = swiperCore;
					setActive(activeIndex);
				}}
			>
				{category.testimonials.map((item, i) => (
					<SwiperSlide
						key={`testimonialslide${i}`}
						className=""
					>
						<div className="container space-y-12 text-center md:text-left">
							<StaticImage src="../../assets/images/quote.png" alt="" className="w-[54px]" />
							<div className="space-y-10">
								<div className="t-55 max-w-[816px]">
									{i === 0}
									{item.content}
								</div>
								<div className="flex items-center justify-center md:justify-start space-x-5">
									{item?.by?.image && (
										<div className="w-[60px] h-[60px] rounded-full  overflow-hidden">
											<MediaImage image={item?.by?.image} className="w-full h-full" objectFit="cover" objectPosition="center" />
										</div>
									)}
									<div className="t-21-normal" dangerouslySetInnerHTML={{ __html: item?.by?.namerole }} />
								</div>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
			<div className=" container flex flex-col items-center md:items-end space-y-5 lg:space-y-0 lg:flex-row justify-between mt-20 lg:mt-32">
				<div className="flex space-x-3 lg:order-1">
					{category.testimonials.map((item, i) => (
						<button key={i} onClick={() => overrideActive(i)} className={`bg-white block bg-opacity-30 hover:bg-opacity-100 transition-colors duration-200 ease-in-out w-3 h-3 rounded-full ${active === i && 'bg-opacity-100'}`}></button>
					))}
				</div>
				<div className="hidden md:block trustpilot-widget w-[189px] sm:w-[420px] sm:-ml-2.5" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5fc67a080737a300019b2dd4" data-style-height="20px" data-style-width="100%" data-theme="dark" data-text-color="#FFFFFF">
					<a href="https://www.trustpilot.com/review/yeshearing.com" target="_blank" rel="noopener"><span className="hidden">Trustpilot</span></a>
				</div>

			</div>
		</motion.div>
	)
}
