import React from 'react';
import { MediaImage } from '~/components/elements/Media';
import { LinkInner } from '~/components/elements/Link';

export const Grid = (props) => {
	const { blocks } = props;
	return (
		<div className="container">
			<div className="grid md:grid-cols-2 gap-7">
				{blocks?.map((block, i) => (
					<div key={`gridBlock${i}`} className="bg-blue-light rounded-[10px] p-8 lg:px-12 lg:pb-10 lg:pt-12 space-y-12 lg:space-y-16">
						<div className="space-y-12">
							<MediaImage image={block.image} className="block w-auto max-h-[50px] max-w-[261px]" objectPosition="left" />
							<div className="t-black-65">
								{block.content}
							</div>
						</div>
						<LinkInner {...block.gridLink} />
					</div>
				))}
			</div>
		</div>
	);
};
