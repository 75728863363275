import { useField } from 'formik';
import React from 'react';

export default function NewsletterField(props) {
  const { label, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  return (
    <div className={`max-w-[480px] flex flex-col md:flex-row !text-black flex-auto relative space-y-5 md:space-y-0  ${props.className || ''}`}>
      <label className={`screenreader-text`}>{label}</label>
      <input
        placeholder={label}
        className={`border rounded-[50px] border-navy border-opacity-20  t-black-65 bg-white w-full px-7 py-3 t-17 outline-none focus:border-opacity-100 transition-colors  ${isInvalid && 'border border-error !border-opacity-100 '
          }`}
        {...field}
        {...other}
      />
      {isInvalid && <ErrorMessage className=" top-[98%] left-7" error={meta.error} />}
      <button type="submit" className="md:ml-[-50px] button button-primary ">
        <span className="">Send message</span>
        <span className="" aria-hidden>
          Send message
        </span>
      </button>
    </div>
  );
}

export const ErrorMessage = ({ error = '', className = '' }) => {
  return (
    <div className={`text-[10px] text-error flex items-center mt-1 left-0 absolute ${className}  `}>{error}</div>
  );
};
