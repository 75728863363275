import React, { useRef, useState } from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { Play } from '~/components/elements/Icon';

export const Video = (props) => {
	const { video, layout } = props;

	const [playing, setPlaying] = useState(false)
	const videoRef = useRef(null)

	const handleVideoPlay = () => {
		videoRef.current.play()
		videoRef.current.controls = true
		setPlaying(true)
	}

	if(!video?.localFile?.publicURL) return null

	return (
		<div className="container ">
			<div className="bg-blue-light p-5 rounded-[8px] overflow-hidden relative">
				<video src={video?.localFile?.publicURL} ref={videoRef}  />
				<div className={`absolute inset-0 group cursor-pointer transition-opacity duration-300 ease-in-out ${playing && 'opacity-0 pointer-events-none'}`} onClick={handleVideoPlay}>
					<div className="absolute top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2 w-20 h-20 md:w-[126px] md:h-[126px] flex items-center justify-center">
						<div className="rounded-full bg-blue text-white absolute inset-0 group-hover:scale-110 transform transition-transform duration-300 ease-in-out"></div>
						<div className="w-5 md:w-[30px] fw-svg relative text-white">
							<Play />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
