import React from 'react';
import { MediaImage } from '~/components/elements/Media';
import { UnderlineLink, LinkInner } from '~/components/elements/Link';

export const Services = (props) => {
	const { heading, services } = props;
	return (
		<div className="container">
			<div className="space-y-10 lg:space-y-32">
				{heading && <h2 className="t-65 text-center max-w-[705px] mx-auto">{heading}</h2>}
				
				<div className="space-y-10 lg:space-y-32">
					{services.map((service, i) => (
						<div className={`space-y-10 lg:space-y-0 lg:grid grid-cols-12 pb-10 lg:pb-16 ${services.length !== (i + 1) && 'step-border'} `}>
							<div className="col-start-2 col-span-5">
								<div className="relative h-full">
									<div className="top-28 sticky rounded-[8px] overflow-hidden">
										<MediaImage image={service.image} />
									</div>
								</div>
							</div>
							<div className="col-start-8 col-span-5">
								<div className="space-y-8">
									<div className="space-y-6">
										{service?.subheading && <h6 className="font-body font-semibold t-14-upper tracking-[.16em] text-green ">{service?.subheading}</h6>}
										{service?.heading && <h4 className="t-32">{service?.heading}</h4>}
									</div>
									<div className="space-y-4">
										{service?.questions.map((question, i) => (
											<div className="bg-white p-8 rounded-[8px] space-y-5">
												{question?.question && <div className="t-20 text-navy font-semibold">{question?.question}</div>}
												{question?.answer && <p className="t-18 text-black-65">{question?.answer}</p>}
												{/* <pre>{JSON.stringify(question, null, 4)}</pre> */}
											</div>
										))}
									</div>
									<LinkInner {...service.link}  />
								</div>

								
							</div>
							
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
