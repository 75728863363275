import React from 'react';
import { MediaImage } from '~/components/elements/Media';
import { Pin } from '~/components/elements/Icon';

export const Customers = (props) => {
	const { heading, customers } = props;
	return (
		<div className="container">
			<div className="space-y-20">
				<h2 className="t-65">{heading}</h2>
				<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-y-10 gap-x-6">
					{customers?.map((customer, i) => (
						<div className="space-y-7">
							<div className="aspect-w-4 aspect-h-3 overflow-hidden rounded-lg transform-gpu">
								<MediaImage image={customer.image} className="!absolute w-full h-full" objectFit="cover" />
							</div>
							<div className="space-y-1">
								{customer.name && <div className="t-21-normal leading-[-.02em] text-navy" dangerouslySetInnerHTML={{ __html: customer.name }} />}
								{customer.location && <div className="t-18 font-medium text-green space-x-1.5 flex items-center"><Pin /><div>{customer.location}</div></div>}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
