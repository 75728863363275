import { Link } from 'gatsby';
import React, { useState } from 'react';
import { MediaImage } from '~/components/elements/Media';
import { useRef } from 'react';
import { useEffect } from 'react';
import { ChevronRight } from '~/components/elements/Icon';
import { UnderlineLink, LinkInner } from '~/components/elements/Link';
import useBlogPosts from '~/hooks/useBlogPosts';
import moment from "moment";
import CustomCursor from "~/components/elements/CustomCursor";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, FreeMode } from "swiper";
import { Newsletter } from "~/components/flexible/Newsletter";

import "swiper/css";

export const Resources = (page) => {
	const { heading, content, resources } = page.resources;
	const { allWpPost } = useBlogPosts();

	return (
		<>
			<Masthead heading={heading} content={content} />
			<ResourcesInner resources={resources} allWpPost={allWpPost} />
			<Newsletter />
		</>
	);
};

export const Masthead = ({ heading, content }) => {
	return (
		<section className="section section-top section-bot bg-blue-light">
			<div className="container">
				<div className="max-w-[1002px] mx-auto text-center space-y-5">
					<h1 className="t-75">{heading}</h1>
					<p className="t-18 max-w-[587px] mx-auto">{content}</p>
				</div>
			</div>
		</section>
	)
}


export const ResourcesInner = ({ resources, allWpPost }) => {
	return (
		<div>
			{resources.map((resource, i) => (
				<>
					{resource.layout === 'featured' ? (
						<Featured {...resource} allWpPost={allWpPost} />
					) : resource.layout === 'slider' ? (
						<Slider {...resource} allWpPost={allWpPost} />
					) : resource.layout === 'videos' ? (
						<Videos {...resource} allWpPost={allWpPost} />
					) : resource.layout === 'guides' ? (
						<Guides {...resource} allWpPost={allWpPost} />
					) : resource.layout === 'articles' && (
						<Articles {...resource} allWpPost={allWpPost} />
					)}
				</>
			))}

		</div>
	)
}


export const Featured = (props) => {

	const { heading, category, link, allWpPost } = props

	const results = allWpPost.nodes.filter(({ categories }) => categories.nodes[0].id === category.id)

	return (
		<section className="section section-bot relative">
			<div className="absolute top-0 left-0 right-0 h-1/4 bg-blue-light"></div>
			<div className="container space-y-12">

				<Link to={results[0].uri} className={`card-shadow block md:grid grid-cols-2 gap-x-10 md:gap-x-0 items-center rounded-[20px] bg-white p-4 relative flex-shrink-0 transform `}>


					<div className="h-[50vw] md:h-full bg-blue-light rounded-[8px] overflow-hidden md:order-[1]">
						<div className="bg-blue-light"></div>
					</div>

					<div className="space-y-10 md:px-8 py-8">
						<div className="space-y-6">
							<h6 className="font-body font-semibold t-14-upper tracking-[.16em] text-blue">Featured</h6>
							<h3 className="t-32 max-w-[450px]">{results[0].title}</h3>
							<div className="flex items-center space-x-4">
								<div className="w-[58px] h-[58px] rounded-full overflow-hidden bg-blue-light"></div>
								<div className="space-y-0.5">
									<div className="font-semibold text-navy t-15">Joe Bloggs</div>
									<div className="text-navy text-opacity-60 t-15">Audiologist</div>
								</div>
							</div>
							{results[0].postTypePost.previewSummary && (<p className="t-15 t-black-65 max-w-[470px]">{results[0].postTypePost.previewSummary}</p>)}
							
							<UnderlineLink link={{ title: "Read more" }} />
						</div>
					</div>



				</Link>

				<div className="space-y-6 md:space-y-0 md:grid grid-cols-2 md:gap-x-6">
					{results?.slice(1, 3).map((result, i) => (
						<ResourceCard category={category} result={result} />
					))}
				</div>

				<div className="flex md:justify-end">
					<LinkInner link={link} type="underline" />
				</div>

			</div>
		</section>
	)
}



export const ResourceCard = ({ category, result }) => {
	return (
		<Link to={result.uri} className={`card-shadow block rounded-[20px] bg-white p-4 pb-8 relative flex-shrink-0 transform space-y-8`}>

			<div className="aspect-w-16 aspect-h-9 rounded-[8px] overflow-hidden">
				<div className="bg-blue-light"></div>
			</div>

			<div className="space-y-10 md:px-8">
				<div className="space-y-6">
					{category?.name && <h6 className="font-body font-semibold t-14-upper tracking-[.16em] text-blue">{category.name}</h6>}
					<h3 className="t-32">{result.title}</h3>
					{result.postTypePost.previewSummary && (<p className="t-15 t-black-65">{result.postTypePost.previewSummary}</p>)}
					<div className="flex items-center space-x-4">
						<div className="w-[58px] h-[58px] rounded-full overflow-hidden bg-blue-light"></div>
						<div className="space-y-0.5">
							<div className="font-semibold text-navy t-15">Joe Bloggs</div>
							<div className="text-navy text-opacity-60 t-15">Audiologist</div>
						</div>
					</div>
				</div>

				<UnderlineLink link={{ title: "Read more" }} />
			</div>

		</Link>
	)
}


export const Slider = (props) => {

	const { heading, category, link, allWpPost } = props

	const results = allWpPost.nodes.filter(({ categories }) => categories.nodes[0].id === category.id)

	return (
		<section className="section section-top section-bot overflow-hidden">
			<div className="container ">
				<SliderInner heading={heading} link={link} category={category} results={results} />
			</div>
		</section>

	)
}


export const SliderInner = ({heading, link, results, category}) => {
	return (
		<div className="space-y-12">
			<div className="flex flex-col md:flex-row md:items-center space-y-5 md:space-y-0">
				{heading && <h2 className="t-65 md:w-3/5">{heading}</h2>}
				<div className="md:ml-auto">
					<LinkInner link={link} type="button" />
				</div>
			</div>

			<CustomCursor >
				<Swiper
					modules={[A11y, FreeMode]}
					freeMode
					className={`w-full md:!mx-auto !overflow-visible z-10 relative`}
					slidesPerView={'auto'}
				>
					{results?.map((result, i) => (
						<SwiperSlide
							key={`productslide${i}`}
							className="!max-w-[320px] md:!max-w-[486px] mr-6 last:pr-0 box-content"
						>
							<Link to={result.uri} className={`block rounded-[20px] !w-[320px] md:!w-[486px] relative flex-shrink-0 transform  space-y-8`}>

								<div className="aspect-w-5 aspect-h-3 rounded-[5px] overflow-hidden">
									<div className="bg-blue-light"></div>
								</div>

								<div className="space-y-5">
									<div className="flex text-green items-center">
										{result.categories.nodes[0].name && <h6 className="font-body font-normal leading-none text-green border-r border-r-green pr-2 mr-2">{result.categories.nodes[0].name}</h6>}
										<time className="inline-block text-green leading-none">
											{moment(result.date).format("MMM DD, YYYY")}
										</time>
									</div>

									<h3 className="t-32">{result.title}</h3>
								</div>

							</Link>
						</SwiperSlide>
					))}
				</Swiper>
			</CustomCursor>
		</div>
	)
}


export const Videos = (props) => {

	const { heading, category, link, allWpPost } = props

	const results = allWpPost.nodes.filter(({ categories }) => categories.nodes[0].id === category.id)

	return (
		<section className="section section-top section-bot bg-blue-light">
			<div className="container space-y-12">
				{heading && <h2 className="t-50">{heading}</h2>}

				<div className="space-y-6 md:space-y-0 md:grid grid-cols-2 md:gap-x-6">

					{results?.slice(0, 2).map((result, i) => (
						<Link to={result.uri} className={`card-shadow block rounded-[20px] bg-white p-4 pb-8 relative flex-shrink-0 transform  space-y-8`}>

							<div className="aspect-w-16 aspect-h-9 rounded-[8px] overflow-hidden">
								<div className="bg-blue-light"></div>
							</div>

							<div className="space-y-10 md:px-8">
								<div className="space-y-6">
									{category.name && <h6 className="font-body font-semibold t-14-upper tracking-[.16em] text-blue">{category.name}</h6>}
									<h3 className="t-32">{result.title}</h3>
									<div className="flex items-center space-x-4">
										<div className="w-[58px] h-[58px] rounded-full overflow-hidden bg-blue-light"></div>
										<div className="space-y-0.5">
											<div className="font-semibold text-navy t-15">Joe Bloggs</div>
											<div className="text-navy text-opacity-60 t-15">Audiologist</div>
										</div>
									</div>
								</div>

								<UnderlineLink link={{ title: "Watch Video" }} />
							</div>

						</Link>
					))}
				</div>

				<div className="flex md:justify-end">
					<LinkInner link={link} type="underline" />
				</div>

			</div>
		</section>
	)
}

export const Guides = (props) => {

	const { heading, category, link, allWpPost } = props

	const results = allWpPost.nodes.filter(({ categories }) => categories.nodes[0].id === category.id)

	return (
		<section className="section section-top section-bot overflow-hidden">
			<div className="container space-y-12">
				{heading && <h2 className="t-50">{heading}</h2>}

				<CustomCursor >
					<Swiper
						modules={[A11y, FreeMode]}
						freeMode
						className={`w-full md:!mx-auto !overflow-visible z-10 relative`}
						slidesPerView={'auto'}
					>
						{results?.map((result, i) => (
							<SwiperSlide
								key={`productslide${i}`}
								className="!max-w-[384px] mr-6 last:pr-0 box-content"
							>
								<Link to={result.uri} className={`card-shadow block rounded-[20px] bg-white p-4 pb-8 !w-[384px] relative flex-shrink-0 transform  space-y-5`}>

									<div className="aspect-w-5 aspect-h-3 rounded-[5px] overflow-hidden">
										<div className="bg-blue-light"></div>
									</div>

									<div className="space-y-6">
										<div className="space-y-3">
											{category.name && <h6 className="font-body font-semibold t-14-upper tracking-[.16em] text-blue">{category.name}</h6>}
											<h3 className="t-20 !leading-[1.1]">{result.title}</h3>
											{result.postTypePost.previewSummary && (<p className="t-15 t-black-65">{result.postTypePost.previewSummary}</p>)}
										</div>

										<UnderlineLink link={{ title: "Read more" }} />
									</div>

								</Link>
							</SwiperSlide>
						))}
					</Swiper>
				</CustomCursor>

				<div className="flex md:justify-end">
					<LinkInner link={link} type="underline" />
				</div>

			</div>
		</section>

	)
}

export const Articles = (props) => {

	const { heading, category, link, allWpPost } = props

	const results = allWpPost.nodes.filter(({ categories }) => categories.nodes[0].id === category.id)

	return (
		<section className="section">
			<div className="container space-y-12">
				{heading && <h2 className="t-50">{heading}</h2>}

				{results.map((result, i) => (
					<Link to={result.uri} className="flex flex-col space-y-8 md:space-y-0 md:flex-row items-start py-8 border-t border-blue-light last:border-b">
						<div className="w-full md:w-2/3 space-y-4">
							{category.name && <h6 className="font-body font-semibold t-14-upper tracking-[.16em] text-blue">{category.name}</h6>}
							<h3 className="t-32 max-w-[436px]">{result.title}</h3>
							{result.postTypePost.previewSummary && (<p className="t-17 t-black-65">{result.postTypePost.previewSummary}</p>)}
							<UnderlineLink link={{ title: "Read more" }} />
						</div>
						<div className="w-full md:w-1/3 flex md:justify-end">
							<div className="flex flex-col space-y-5 items-start">
								<time className="inline-block text-blue font-bold t-15">
									{moment(result.date).format("MMM DD, YYYY")}
								</time>
								<div className="flex items-center space-x-4">
									<div className="w-[58px] h-[58px] rounded-full overflow-hidden bg-blue-light"></div>
									<div className="space-y-0.5">
										<div className="font-semibold text-navy t-15">Joe Bloggs</div>
										<div className="text-navy text-opacity-60 t-15">Audiologist</div>
									</div>
								</div>
							</div>
						</div>
					</Link>
				))}

				<div className="flex md:justify-end">
					<LinkInner link={link} type="underline" />
				</div>

			</div>
		</section>

	)
}