import React from 'react';
import { MediaImage } from '~/components/elements/Media';
import { LinkInner } from '~/components/elements/Link';
import { SliderInner } from '~/components/landing/Resources';

export const FeaturedResources = (props) => {

	const { heading, link, resources } = props;

	const results = []

	resources.map((result) => (
		results.push(result.post)
	))

	return (
		<div className="container overflow-hidden">
			<SliderInner heading={heading} link={link} results={results} />
		</div>
	);
};
