import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus } from '~/components/elements/Icon';

export const Faqs	 = (props) => {
	const { heading, content, faqs } = props;
	return (
		<div className="container">
			<div className="space-y-10 lg:space-y-0 lg:grid grid-cols-12">
				<div className="col-span-5">
					<div className="space-y-4">
						<h3 className="t-50">{heading}</h3>
						<div className="prose" dangerouslySetInnerHTML={{__html: content}} />
					</div>
				</div>
				<div className="col-span-6 col-start-7">
					<Items {...props} />
				</div>
			</div>
		</div>
	);
};

export const Items = ({ faqs }) => {

  const [expanded, setExpanded] = useState(null);

  return (
    <div className="bg-white py-2 rounded-[10px]">
      {faqs.map((faq, i) => (
        <div className="border-t border-t-blue-light py-4 px-5  md:px-8 first:border-t-0" key={`faq${i}`}>
          <header className="relative cursor-pointer group pr-5" onClick={() => setExpanded(expanded === i ? null : i)}>
            <div className={`t-22-normal font-medium text-navy`}>
              {faq.question}
            </div>
            <div
              className={`text-navy absolute top-2 md:top-1.5 lg:top-2.5 right-0 transition-all duration-300 fw-svg w-3 h-auto ease-in-out group-hover:text-blue ${
                expanded === i && 'rotate-45 '
              } `}
            >
              <Plus />
            </div>
          </header>
          <AnimatePresence>
            {expanded === i && (
              <motion.div
                className="overflow-hidden"
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: 'auto' },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.3 }}
              >
                <div className="pt-2 pr-2 t-18 t-black-65 whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: faq.answer }} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
};
