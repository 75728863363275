import React from 'react';
import { MediaImage } from '~/components/elements/Media';
import { MaybeLink, LinkInner } from '~/components/elements/Link';
import { Star } from '~/components/elements/Icon';
import useProductAttributes from '~/hooks/useProductAttributes';
import { StaticImage } from 'gatsby-plugin-image';

export const ShopBy = (props) => {
	const { heading, type, features, manufacturers, levels } = props;


	const { allWcProductsAttributes } = useProductAttributes();


	return (
		<div className="container">
			<div className="space-y-12">
				<h3 className="t-28">{heading}</h3>
				{type === 'manufacturer' ? (
					<Manufacturers items={manufacturers} attributes={allWcProductsAttributes} />
				) : type === 'level' ? (
					<Levels items={levels} attributes={allWcProductsAttributes} />
				) : type === 'feature' && (
					<Features items={features} attributes={allWcProductsAttributes} />
				)}
			</div>

		</div>
	);
};

export const Manufacturers = ({ items, attributes }) => {

	const { allWcProductsAttributes } = useProductAttributes();

	const imageClasses = ['w-full h-full w-[130px] h-[70px] mx-auto']

	return (
		<div className="grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-10">

			{allWcProductsAttributes?.nodes?.map((node, i) => (
				<>
					{node?.slug === 'pa_brand' && (
						<>
							{node?.attribute_options?.map((option, i) => (
								<MaybeLink link={{ url: `/products?brands=${option.slug}#products` }} className="bg-white flex justify-center items-center p-5 card-shadow rounded-[12px] border border-black border-opacity-[.15]">
									{option.slug === 'oticon' ? (
										<StaticImage src="../../assets/images/brands/oticon.png" alt={option?.name} className={imageClasses.join(' ')} objectFit="contain" />
									) : option.slug === 'phonak' ? (
										<StaticImage src="../../assets/images/brands/phonak.png" alt={option?.name} className={imageClasses.join(' ')} objectFit="contain" />
									) : option.slug === 'resound' ? (
										<StaticImage src="../../assets/images/brands/resound.png" alt={option?.name} className={imageClasses.join(' ')} objectFit="contain" />
									) : option.slug === 'signia' ? (
										<StaticImage src="../../assets/images/brands/signia.png" alt={option?.name} className={imageClasses.join(' ')} objectFit="contain" />
									) : option.slug === 'starkey' ? (
										<StaticImage src="../../assets/images/brands/starkey.png" alt={option?.name} className={imageClasses.join(' ')} objectFit="contain" />
									) : option.slug === 'unitron' && (
										<StaticImage src="../../assets/images/brands/unitron.png" alt={option?.name} className={imageClasses.join(' ')} objectFit="contain" />
									)}
								</MaybeLink>
							))}
						</>
					)}
				</>
			))}


		</div>
	)
}

export const Levels = ({ items, attributes }) => {

	const { nodes } = attributes


	return (
		<div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5 sm:gap-6">

			{nodes.map((node, i) => (
				<>
					{node?.slug === 'pa_level' && (
						<>
							{node?.attribute_options?.map((option, i) => (
								<MaybeLink link={{ url: `/products?levels=${option.slug}#products` }} className="block rounded-[6px] border border-black border-opacity-[.15]">
									<div className="aspect-w-3 aspect-h-2">
										<StaticImage src="../../assets/images/level-placeholder.jpeg" alt="" className="!absolute w-full h-full" objectFit="cover" />
									</div>
									<div className="p-7">
										<div className="space-y-5 pb-5 border-b border-black border-opacity-[.15]">
											<div className="flex items-blue text-blue space-x-1">
												{[...Array(4 - parseInt(i))].map((item, i) => (
													<div className="fw-svg w-6">
														<Star />
													</div>
												))}
											</div>
											<h4 className="t-32">{option?.name}</h4>
										</div>
										<div className="space-y-5 pt-5">
											{/* todo: Connect to the CMS */}
											<h6 className="text-green t-15 font-heading">from <span className="!text-[23px]">$3395/</span> pair </h6>
											<p className="t-black-65">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
											<div className="fw-button">
												<LinkInner link={{ url: `/products?level=${option.slug}`, title: `Shop ${option?.name}` }} type="button-outline-small" />
											</div>
										</div>
									</div>
								</MaybeLink>
							))}
						</>
					)}
				</>
			))}
		</div>
	)
}

export const Features = ({ items, attributes }) => {

	const { nodes } = attributes

	return (
		<div className="grid grid-cols-2 lg:grid-cols-4 gap-5 md:gap-10">
			{nodes.map((node, i) => (
				<>
					{node?.slug === 'pa_feature' && (
						<>
							{node?.attribute_options?.map((option, i) => (
								<MaybeLink link={{ url: `/products?features=${option.slug}#products` }} className="text-center flex flex-col justify-center items-center p-5 py-6 card-shadow rounded-[12px] border border-black border-opacity-[.15] space-y-5">
									<div className="t-28 text-navy">{option.name}</div>
								</MaybeLink>
							))}
						</>
					)}
				</>
			))}
		</div>
	)
}
