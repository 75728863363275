import React from 'react';
import { MediaImage } from '~/components/elements/Media';

export const Rewards = (props) => {

	const { heading, rewards } = props;

	return (
		<div className="container">
			<div className="space-y-16">
				<h3 className="t-50 text-center lg:text-left">{heading}</h3>
				<div className="space-y-5 lg:space-y-0 max-w-[468px] mx-auto lg:max-w-none lg:mx-0 lg:grid grid-cols-3 lg:gap-x-6">
					{rewards?.map((reward, i) => (
						<div className="bg-blue-light p-5 lg:px-5 lg:py-8 xl:px-12 xl:py-10 flex items-center justify-between space-x-10 rounded-lg">
							<h5 className="t-24">{reward.heading}</h5>
							<div className="w-20 flex-shrink-0">
								<MediaImage image={reward.image}  />
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
			
