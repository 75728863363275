import React from 'react';
import { MediaImage } from '~/components/elements/Media';

export const Program = (props) => {
	const { heading, content, blocks } = props;
	return (
		<div className="px-5 md:px-10 lg:px-[56px]">
			<div className="container container-full">
				<div className="space-y-10 lg:space-y-28">
					<div className="max-w-[715px] mx-auto text-center space-y-7">
						{heading && <h2 className="t-59">{heading}</h2>}
						{content && <p className="t-18 t-black-65">{content}</p>}
					</div>
					<div className="space-y-7 relative">
						{blocks?.map((block, i) => (
							<Block {...block} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default function Block({ backgroundColour, content, heading, image }) {

	const backgroundColourToPush = getBgClass(backgroundColour)

	return (
		<div className={`rounded-[20px] py-10 lg:py-20 px-5 lg:grid grid-cols-12 items-center sticky top-32 ${backgroundColourToPush}`}>
			<div className="col-span-4 col-start-2">
				<MediaImage image={image} objectFit="contain" className={``} />
			</div>
			<div className="col-span-5 col-start-7">
				<div className="max-w-[480px] space-y-4">
					<h3 className="t-59 !text-navy">{heading}</h3>
					<p className="t-18 t-black-65">{content}</p>
				</div>
			</div>
		</div>
	)
}



export const getBgClass = (background) => {

	if (background === 'purple') {
		return 'bg-[#F0F4F9]'
	} else if (background === 'green') {
		return 'bg-green-light'
	} else if (background === 'yellow') {
		return 'bg-[#FFFCE5]'
	} else if (background === 'red') {
		return 'bg-[#FCF3EB]'
	} else if (background === 'blue') {
		return 'bg-[#EFF4FE]'
	}
};
