import React, { useState } from 'react'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputField from '~/components/elements/forms/InputField';
import StateField from '~/components/elements/forms/StateField';
import CheckboxField from '~/components/elements/forms/CheckboxField';

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  terms: Yup.boolean().oneOf([true], 'This field must be checked'),
});

export default function AudiogramForm({ open, set_open }) {

  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setIsSubmitted] = useState(false);

  return (
    <div className="bg-white shadow rounded-[10px]  w-full  md:max-w-[530px] mr-auto xxl:mx-auto px-5 py-8 md:px-10 md:py-12 text-left relative">
      <div className="space-y-4">
        <h2 className="t-39 !text-navy">Upload your audiogram</h2>
        <Formik
          initialValues={{
            name: '',
            email: '',
            state: '',
            terms: false
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setFieldValue }) => {
            const formData = new FormData();

            for (var key in values) {
              formData.append(key, values[key]);
            }

            fetch("https://hooks.zapier.com/hooks/catch/9806274/b84i1cp/", {
              method: "POST",
              body: formData,
            })
              .then((response) => {
                if (response.status === 200) {
                  setIsSubmitted(true);
                }
              })
              .catch((error) => {
                setErrorMessage(error);
              });
            setErrorMessage(""); // reset error message
          }}
        >
          {(props) => {
            return (
              <Form className="">
                <div className="space-y-5">
                  {errorMessage?.length > 0 && (
                    <div
                      className="p-4 mt-4 border text-error border-error rounded-[4px]"
                      dangerouslySetInnerHTML={{ __html: errorMessage }}
                    />
                  )}

                  {submitted && (
                    <div className="p-4 mt-4 border text-green border-green rounded-[4px]">
                      Thanks for getting in touch, we'll get back to you soon.
                    </div>
                  )}
                  <div className={`grid gap-y-5 transition-opacity duration-300 ease-in-out ${submitted && 'opacity-50 pointer-events-none'}`}>
                    <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5">
                      <div className="md:w-1/3">
                        <InputField name="name" label="First name" />
                      </div>
                      <StateField name="state" label="Your state" />
                    </div>
                    <InputField name="email" label="Email address" placeholder="Your email address" />
                    <CheckboxField
                      name="terms"
                      label={`I agree to the <a class="underline" href="/terms-conditions">terms and conditions</a> `}
                    />
                  </div>
                  <div className="fw-button">
                    <button type="submit" className="button button-primary w-full ">
                      <span className="">Submit now</span>
                      <span className="" aria-hidden>
                        Submit now
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  )
}

