import { useField } from 'formik';
import React from 'react';
import { ErrorMessage } from './InputField';

export default function InputInputFileFieldField(props) {
  const { label, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  return (
    <div className={`flex flex-col !text-black items-start flex-auto relative space-y-1   ${props.className || ''}`}>
      <label className={`font-medium t-17`}>{label}</label>
      <input
        type="file"
        placeholder={label}
        className={`border rounded-[4px] border-navy border-opacity-20  t-black-65 bg-transparent w-full px-4 py-3 t-17 outline-none focus:border-opacity-100 transition-colors  ${
          isInvalid && 'border border-red-500'
        }`}
        {...field}
        {...other}
      />
      {isInvalid && <ErrorMessage className=" top-[98%]" error={meta.error} />}
    </div>
  );
}
