import React from 'react';
import { Media, MediaImage } from '~/components/elements/Media';
import { LinkInner } from '~/components/elements/Link';

export const TwoColumnsCalculator = (props) => {
	const { columnA, columnB,  showLogos, headingSize, headingWidth } = props;
	return (
		<div className="container">
			<div className={`flex flex-col-reverse lg:flex-row items-center lg:justify-between`}>
				<ColumnA {...columnA} showLogos={showLogos} headingSize={headingSize} headingWidth={headingWidth}  />
				<ColumnB {...columnB} />
			</div>
		</div>
	);
};

export const ColumnA = ({heading, content, links, logos, showLogos}) => {

	return (
		<div className="w-full lg:w-2/5 mt-8 lg:mt-0">
			<div className={`space-y-4 lg:space-y-8 max-w-[480px]`}>
				{heading && <h3 className={`t-59`}>{heading}</h3>}
				{content && <p className="t-18 t-black-65 max-w-[460px] whitespace-pre-wrap">{content}</p>}
				{showLogos && (
					<div className="flex py-4 md:py-0 space-x-2 flex-wrap items-center -ml-6">
						{logos?.map((logo, i) => (
							<MediaImage image={logo} className="block w-auto flex-1 h-full max-w-[116px] max-h-[27px]" />
						))}
					</div>
				)}
				{links && (
					<div className={`flex flex-col space-y-5 md:space-y-0 md:flex-row flex-wrap md:items-center md:space-x-12`}>
						{links?.map((link, i) => (
							<div>
								<LinkInner {...link} />
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	)
}

export const ColumnB = ({image, type, video}) => {
	return (
		<div className="w-full lg:w-1/2 text-center">
			Calculator goes here
		</div>
	)
}
