import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MediaImage } from '~/components/elements/Media';

export const Testimonial = (props) => {
	const { heading, content, by} = props;
	return (
		<div className="container">
			<StaticImage src="../../assets/images/testimonial-bg.png" alt="" className="w-[32%] max-w-[460px] !absolute top-0 right-0" />
			<div className="space-y-12 relative">
				<StaticImage src="../../assets/images/quote.png" alt="" className="w-[54px]" />
				<div className="space-y-8">
					<div className="space-y-6 max-w-[816px]">
						<h5 className="t-39">{heading}</h5>
						<div className="t-22-normal">{content}</div>
					</div>
					<div className="flex items-center space-x-5">
						<MediaImage image={by.image} className="w-[60px] h-[60px] rounded-full transform-gpu border-2 border-white overflow-hidden" />
						<div dangerouslySetInnerHTML={{__html:by.namerole}} />
					</div>
				</div>
			</div>
		</div>
	);
};
