import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { LinkInner } from '~/components/elements/Link';
import { MediaImage } from '~/components/elements/Media';

export const HowItWorks = (props) => {
	const { heading, steps, howItWorksLink, headingSize} = props;

	const headingClasses = ['']

	if(headingSize === '60'){
		headingClasses.push('t-60')
	} else {
		headingClasses.push('t-45')
	}

	return (
		<div className="container space-y-8 lg:space-y-16">
			<div className="text-center space-y-5">
				<h2 className={`max-w-[648px] mx-auto ${headingClasses.join(' ')}`}>{heading}</h2>
				{howItWorksLink && <LinkInner {...howItWorksLink} />}
			</div>
			<div className="max-w-[354px] mx-auto lg:max-w-none lg:mx-0 grid gap-y-10 lg:gap-y-0 lg:grid-cols-3 lg:gap-x-[69px]">
				{steps.map((step, i) => (
					<div className="space-y-10 text-center">
						<div className="aspect-w-10 aspect-h-8">
							<MediaImage image={step.image} className="!absolute object-cover rounded-[8px]" objectFit="cover" />
						</div>
						<div className="space-y-4 max-w-[295px] mx-auto">
							<h5 className="t-21">{step.heading}</h5>
							{step.content && <p>{step.content}</p>}
						</div>
					</div>
				))}
			</div>
			
		</div>
	);
};
