import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { LinkInner, MaybeLink, UnderlineLink } from '~/components/elements/Link';
import { MediaImage } from '~/components/elements/Media';
import { Online } from '~/components/elements/Icon';

export const Support = (props) => {
	const { heading, blocks } = props;
	return (
		<div className="container space-y-8 lg:space-y-[54px]">
			<div className="text-center space-y-7">
				{heading && <h2 className="t-21">{heading}</h2>}
			</div>
			<div className="lg:grid max-w-[420px] mx-auto lg:max-w-none space-y-6 lg:space-y-0 lg:mx-0 lg:grid-cols-3 lg:gap-x-6">
				{blocks.map((block,i) => (
					<MaybeLink key={i} link={block.link} className="rounded-[8px] overflow-hidden bg-blue-light border border-transparent hover:border-blue transition-colors duration-200 ease-in-out px-5 md:px-10 py-4 flex items-center">
						<div className="relative ml-auto order-1">
							<div className="w-[140px] h-[126px]">
								<MediaImage image={block?.image} className="w-full h-full" objectFit="contain" objectPosition="right" />
							</div>
						</div>
						<div className="space-y-4">
							<div className="space-y-1">
								{block?.heading && <h6 className="t-18">{block?.heading}</h6>}
								{block?.contact && <div className="text-green flex items-center space-x-1">{block.showOnline && <Online />} <div>{block?.contact}</div></div>}
							</div>
							{block?.link &&
								block?.underlineLink ? (
									<UnderlineLink link={block.link} />
								) : (
									<div className="t-14 font-medium whitespace-nowrap text-navy">{block?.link?.title}</div>
								)}
						</div>
					</MaybeLink>
				))}
			</div>
		</div>
	);
};
