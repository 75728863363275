import React from 'react';
import { MediaImage } from '~/components/elements/Media';

export const Features = (props) => {
	const { blocks, heading } = props;
	return (
		<div className="container">
			{heading && <h2 className="t-50 text-center mb-20">{heading}</h2>}
			<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-7">
				{blocks?.map((block, i) => (
					<>
						{block.type === 'video' ? (
							<div className="bg-[#ECF3FF] md:col-span-2 lg:col-span-3 lg:grid grid-cols-2 rounded-[10px]">
								<MediaImage image={block.image} className="col-span-1" />
								<div className="space-y-3 col-span-1 py-10 md:py-5 px-5 md:px-[76px] flex flex-col justify-center">
									<h5 className="t-50">{block.heading}</h5>
									<p className="t-18 t-black-65">{block.content}</p>
								</div>
							</div>
						) : block.type === 'two-col' ? (
							<div className="bg-[#ECF3FF] md:col-span-2 md:grid grid-cols-2 py-10 md:py-12 px-5 md:px-8 rounded-[10px]">
								<div className="space-y-3 col-span-1 max-w-[350px]">
									<h5 className="t-32">{block.heading}</h5>
									<p className="t-18 t-black-65">{block.content}</p>
								</div>
								<div className="col-span-1">
									<MediaImage image={block.image} className="w-[250px] mx-auto" />
								</div>
							</div>
						) : (
							<div className="bg-[#ECF3FF] py-10 px-5 md:py-12 md:px-8 flex flex-col md:col-span-1 rounded-[10px]">
								<div className="space-y-3">
									<h5 className="t-32">{block.heading}</h5>
									<p className="t-18 t-black-65">{block.content}</p>
								</div>
								<MediaImage image={block.image} className="w-[120px] mx-auto !mt-auto" />
							</div>
						)}
					</>
				))}
			</div>
		</div>
	)
}