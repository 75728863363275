import React from 'react';
import { MediaImage } from '~/components/elements/Media';
import { LinkInner } from '~/components/elements/Link'

export const TextContent = (props) => {
	const { heading, content, link, headingMaxWidth, headingSize, logos } = props;

	const headingClasses = ['text-center mx-auto']

	if (headingMaxWidth === '1040') {
		headingClasses.push('max-w-[1040px]')
	} else if (headingMaxWidth === '660') {
		headingClasses.push('max-w-[660px]')
	} else {
		headingClasses.push('max-w-[470px]')
	}

	if (headingSize === '60') {
		headingClasses.push('t-60')
	} else {
		headingClasses.push('t-65')
	}


	return (
		<div className="container">
			<div className="space-y-5 md:space-y-16">
				<div className="space-y-4">
					{heading && <h2 className={headingClasses.join(' ')}>{heading}</h2>}
					{content && <div className="text-center max-w-[588px] mx-auto t-18" dangerouslySetInnerHTML={{ __html: content }}></div>}
				</div>
				<div className="flex justify-center">
					{link?.link && (
						<LinkInner {...link} />
					)}
				</div>
				{logos && (
					<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-y-12 max-w-[980px] mx-auto !mb-10 lg:!mb-20">
						{logos.map((logo, i) => (
							<MediaImage image={logo} className="w-[110px] h-[40px] mx-auto" objectFit="contain" />
						))}
					</div>
				)}
			</div>
		</div>
	);
};
