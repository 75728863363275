import React, { useState } from 'react'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import NewsletterField from '~/components/elements/forms/NewsletterField';

export const Newsletter = (props) => {

	const validationSchema = Yup.object({
		email: Yup.string().email('Invalid email address').required('Required'),
	});


	const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setIsSubmitted] = useState(false);


	return (
		<section className="section section-top section-bot bg-green-light">
			<div className="container py-12">
				<div className="space-y-2 md:space-y-12 text-center">
					<div className="space-y-5">
						<h2 className="t-50 max-w-[878px] mx-auto">
							{submitted ? 'You\'re all set!' : 'Subscribe to our mailing list'}
						</h2>
						<p className="t-17 max-w-[640px] mx-auto">
							{submitted ? 'Stay tuned for updates.' : 'Be the first to hear about latest news, content, and more by subscribing to our mailing list. Check your inbox for a confirmation email!'}
						</p>
					</div>
					<Formik
						initialValues={{
							email: '',
						}}
						validationSchema={validationSchema}
						onSubmit={async (values, { setFieldValue }) => {
							const formData = new FormData();

							for (var key in values) {
								formData.append(key, values[key]);
							}
	
							fetch("https://hooks.zapier.com/hooks/catch/9806274/b84i1cp/", {
								method: "POST",
								body: formData,
							})
								.then((response) => {
									if (response.status === 200) {
										setIsSubmitted(true);
									}
								})
								.catch((error) => {
									setErrorMessage(error);
								});
							setErrorMessage(""); // reset error message
						}}
					>
						{(props) => {
							return (
								<Form className="space-y-5">
									{errorMessage?.length > 0 && (
                    <div
                      className="inline-block p-4 border text-error border-error rounded-[4px]"
                      dangerouslySetInnerHTML={{ __html: errorMessage }}
                    />
                  )}

                  {submitted && (
                    <div className="inline-block p-4 border text-green border-green rounded-[4px]">
                      Thanks for getting in touch, we'll get back to you soon.
                    </div>
                  )}
									<div className={`flex justify-center transition-opacity duration-300 ease-in-out ${submitted && 'opacity-50 pointer-events-none'}`}>
										<NewsletterField name="email" label="Your email address" />
									</div>
								</Form>
							);
						}}
					</Formik>
				</div>
			</div>
		</section>
	);
};
