import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { Items } from '~/components/flexible/Faqs';
import { TeamMember } from '~/components/flexible/FeaturedTeam';

export const TeamMemberFaqs = (props) => {
	const { heading, teamMember } = props;
	return (
		<div className="container">
			<div className="space-y-24">
				<h2 className="t-59 text-center max-w-[751px] mx-auto">{heading}</h2>
				<div className="space-y-10 lg:space-y-0 lg:grid grid-cols-12">
					<div className="col-span-6">
						<Items {...props} />
					</div>
					<div className="col-span-4 col-start-8">
						<TeamMember {...teamMember} />
					</div>
				</div>
			</div>
		</div>
	);
};
