import React from 'react';
import { SmallStar } from '~/components/elements/Icon';

export const Reviews = (props) => {
	const { heading, reviews } = props;

	return (
		<div className="container">
			<div className="max-w-[720px] mx-auto space-y-10 md:space-y-40">
				<div className="space-y-7 flex flex-col items-center text-center">
					<h2 className="t-65">{heading}</h2>
					<div className="trustpilot-widget w-[189px] sm:w-[420px] sm:-ml-2.5" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5fc67a080737a300019b2dd4" data-style-height="20px" data-style-width="100%" data-theme="light" data-text-color="#0B3F6F">
						<a href="https://www.trustpilot.com/review/yeshearing.com" target="_blank" rel="noopener"><span className="hidden">Trustpilot</span></a>
					</div>
				</div>

				<div className="max-w-[996px] mx-auto space-y-5 md:space-y-12">
					{reviews.map((review, i) => (
						<div className="space-y-7 pb-5 md:pb-12 border-b border-b-[#E4E4E4] last:border-b-0">
							<div className="space-y-3">
								<div className="flex items-center justify-between">
									<div className="t-18 font-bold">
										{review.name}
									</div>
									<div className="">
										{review.date}
									</div>
								</div>
								<div className="flex items-center">
									{[...Array(parseInt(review?.stars))].map((item, i) => (
										<SmallStar />
									))}
								</div>
							</div>
							<div className="space-y-3">
								<div className="t-21-normal font-bold">
									{review.heading}
								</div>
								<p className="t-18">
									{review.content}
								</p>
							</div>
						</div>
					))}
				</div>		
			</div>
		</div>
	);
};
