import React, { useLayoutEffect, useState } from 'react';
import { MediaImage } from '~/components/elements/Media';
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y } from "swiper";
import {ChevronRight} from '~/components/elements/Icon'

export const TwoColumnSlider = (props) => {
	const { slides, columnB } = props;
	const [active, setActive] = useState(0)

	const [swiper, setSwiper] = useState(null);

	const slideTo = (index) => swiper?.slideTo(active);

	useLayoutEffect(() => {
		slideTo(active ? active : 0);
	}, [active]);

	const overrideActive = (direction) => {

		let slideToIndex = 0

		if(direction === 'prev'){
			if(swiper.isBeginning){
				slideToIndex = swiper.slides.length
			} else {
				slideToIndex = swiper.activeIndex - 1
			}
		} else {
			if(swiper.isEnd){
				slideToIndex = 0	
			} else {
				slideToIndex = swiper.activeIndex + 1
			}
		}

		setActive(slideToIndex);
			slideTo(slideToIndex)
		
	};

	const buttonClasses = ['w-8 h-8 rounded-full flex items-center justify-center bg-blue hover:bg-navy transition-colors duration-300 ease-in-out']


	return (
		<div className="container">
			<div className=" max-w-[466px] md:max-w-[1066px] mx-auto space-y-5 md:grid grid-cols-2 gap-x-16 items-center">
				<div className="">
					<div className="bg-white rounded-[20px] card-shadow px-5 py-10 md:p-16">
						<Swiper
							onSwiper={setSwiper}
							modules={[A11y]}
							className={`w-full z-10 relative`}
							slidesPerView={'auto'}
							loop
							onSlideChange={(swiperCore) => {
								const {
									activeIndex,
								} = swiperCore;
								setActive(activeIndex);
							}}
						>
							{slides?.map((slide, i) => (
								<SwiperSlide
									key={`twoColumnSlide${i}`}
									className="text-center space-y-2"
								>
									<MediaImage image={slide.image} className="w-[160px] h-[160px] mx-auto" objectFit="center" />
									<div className="space-y-4">
										<h5 className="t-28">{slide.heading}</h5>
										{slide.content && <p>{slide.content}</p>}
									</div>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>
				<div className="">
					<div className="text-center space-y-7 flex flex-col">
						{columnB?.heading && <h3 className="t-45">{columnB.heading}</h3>}
						{columnB?.content && <p className="t-18 t-black-65 max-w-[416px] mx-auto">{columnB.content}</p>}
						<div className="flex justify-center gap-x-2 order-[-1] md:order-1 !mb-10 md:!mb-0">
							<button className={buttonClasses.join(' ')} onClick={() => overrideActive('prev')}>
								<div className="w-5 flex items-center justify-center text-white transform rotate-180 mr-px">
									<ChevronRight />
								</div>
							</button>
							<button className={buttonClasses.join(' ')} onClick={() => overrideActive('next')}>
								<div className="w-5 flex items-center justify-center text-white ml-px">
									<ChevronRight />
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
