import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MediaImage } from '~/components/elements/Media';
import { ChevronRight } from '~/components/elements/Icon';

export const Values	 = (props) => {
	const { heading, content, image, values } = props;
	return (
		<div className="container">
			<div className="space-y-10 lg:space-y-0 lg:grid grid-cols-12">
				<div className="col-span-4">
					<div className="space-y-7">
						<h3 className="t-59">{heading}</h3>
						<div className="prose t-18 t-black-65" dangerouslySetInnerHTML={{__html: content}} />
						<MediaImage image={image} className="w-[145px]" />
					</div>
				</div>
				<div className="col-span-6 col-start-7">
					<Items {...props} />
				</div>
			</div>
		</div>
	);
};

export const Items = ({ values }) => {

  const [expanded, setExpanded] = useState(null);

  return (
    <div className="space-y-5">
      {values.map((value, i) => (
        <div className={`group values-value  ${expanded === i && 'values-value-active'} `} key={`value${i}`} onClick={() => setExpanded(expanded === i ? null : i)}>
          <header className="relative group">
            <div className={`t-21 transition-colors duration-200 ease-out ${expanded === i ? 'text-white' : 'text-navy group-hover:text-white'}`}>
              {value.heading}
            </div>
            <div
              className={`${expanded === i ? 'text-white' : 'text-navy group-hover:text-white'}  absolute top-1 right-0 transition-all duration-200 fw-svg w-2 h-auto ease-in-out ${
                expanded === i ? '-rotate-90 ' : 'rotate-90'
              } `}
            >
              <ChevronRight />
            </div>
          </header>
          <AnimatePresence>
            {expanded === i && (
              <motion.div
                className="overflow-hidden"
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: 'auto' },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.3 }}
              >
                <div className={`pt-4  ${expanded === i ? 'text-white' : 't-black-65'}`} dangerouslySetInnerHTML={{ __html: value.content }} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
};
